import axios from "@/axios.js";

export default {

      /**
   * Move All Selected into `Archived Campaigns`
   * @param {*} id Campaign ID
   */
  ArchiveCampaignAll(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/archive-all`, item)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },


    UnarchiveCampaignAll(item) {
      return new Promise((resolve, reject) => {
        axios
          .post(`nexlaw/unarchive-all`, item)
          .then((response) => { resolve(response); })
          .catch((error) => { reject(error); });
      });
    },
    /**
     * Bookmark All Selected campaign
     * @param {*} id Campaign ID
     */
    BookmarkAll(item) {
        return new Promise((resolve, reject) => {
        axios
            .post(`nexlaw/bookmark-all`,item)
            .then((response) => { resolve(response); })
            .catch((error) => { reject(error); });
        });
    },

    UnbookmarkAll(item) {
      return new Promise((resolve, reject) => {
      axios
          .post(`nexlaw/unbookmark-all`,item)
          .then((response) => { resolve(response); })
          .catch((error) => { reject(error); });
      });
  },


    /**
     * Permanently delete All Selected campaign
     * @param {*} id Campaign ID
     */
    DeleteCampaignAll(item) {
        return new Promise((resolve, reject) => {
          axios
            .delete('nexlaw/delete-all', {
              data: item
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
}